<template>
  <div>
      <vx-card class="mb-4">
        <div class="flex flex-col gap-4 mt-4">
          <div class="w-full">
              <vs-input
                id="user_name"
                :label="$t('name')"
                v-model="model.name"
                class="w-full" />
              <span class="text-danger text-sm" v-show="errors.has('user_name')">{{errors.first('user_name')}}</span>
          </div>
          <div class="w-full">
            <vs-input
              id="user_email"
              :label="$t('email')"
              v-model="model.email"
              class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('user_email')">{{errors.first('user_email')}}</span>
          </div>
      </div>
     <div class="w-full flex gap-2 pt-2 justify-end">
      <vs-button
        class="mr-2"
        @click="loadData"
        :disabled="!validateForm">{{ $t('action.consult') }}</vs-button>
      <vs-button
        class="mr-2"
        type="border"
        @click="clearFilter">{{ $t('action.clear') }}</vs-button>
      </div>
    </vx-card>
    <vs-tabs>
      <vs-tab :label="$t('backoffice.user_title')" v-if="enableTabUser">
        <vx-card
            class="mb-4"
            ref="results">
            <div v-if="user.id">
              <div class="vx-row w-full">
                <div class="vx-col">
                  <b>{{ $t('name') }}:</b>
                </div>
                <div class="vx-col">
                    {{user.name}}
                </div>
              </div>
              <div class="vx-row w-full pt-2">
                <div class="vx-col">
                  <b>{{ $t('email') }}:</b>
                </div>
                <div class="vx-col">
                    {{user.email}}
                </div>
              </div>
              <div class="vx-row w-full pt-2" v-if="user.id">
                <div class="vx-col">
                  <b>{{ $t('cadastrado-desde') }}:</b>
                </div>
                <div class="vx-col">
                    {{$utils.format.longDateWithTime(user.created_at)}}
                </div>
              </div>
              <div class="vx-row w-full pt-2">
                <div class="vx-col">
                  <b>{{ $t('ativado-em') }}:</b>
                </div>
                <div class="vx-col">
                    {{$utils.format.longDateWithTime(user.email_verified_at, 'nao-ativada')}}
                </div>
                <div class="vx-col" v-if="!user.email_verified_at || !user.password_set_at" v-permission="'backoffice.user.activate'">
                  <vs-button
                        type="flat"
                        icon="check"
                        class="float-left"
                        @click="activateUser">{{ $t('action.activate') }}</vs-button>
                </div>
              </div>
              <div class="vx-row w-full pt-2">
                <div class="vx-col">
                  <b>{{ $t('senha-preenchida-em') }}:</b>
                </div>
                <div class="vx-col">
                    {{$utils.format.longDateWithTime(user.password_set_at, 'nao-preenchida')}}
                </div>
              </div>
              <div class="vx-row w-full pt-2">
                <div class="vx-col">
                  <b>{{ $t('ultimo-acesso') }}:</b>
                </div>
                <div class="vx-col">
                    {{$utils.format.longDateWithTime(user.last_access)}}
                </div>
              </div>
              <div class="vx-row w-full pt-2">
                <div class="vx-col">
                  <b>{{ $t('atualizado-em') }}:</b>
                </div>
                <div class="vx-col">
                    {{$utils.format.longDateWithTime(user.updated_at)}}
                </div>
              </div>
              <div class="vx-row mt-4">
                <div class="vx-col w-full flex gap-2 justify-end">
                    <vs-button
                      class="float-right mr-2"
                      @click="resetPassword"
                      :disabled="!validateForm">{{ $t('action.reset_password') }}</vs-button>

                      <vs-button
                        class="float-right mr-2"
                        @click="openChangePassword"
                        :disabled="!validateForm">{{ $t('action.change_password') }}</vs-button>
                  </div>
              </div>
            </div>
             <div class="vx-row w-full pt-2" v-if="!user.id">
              <div class="vx-col">
                {{$t('nenhum-registro-encontrado')}}
              </div>
             </div>
          </vx-card>

          <vs-prompt
            color="warning"
            :title="$t('digite-sua-nova-senha')"
            type="confirm"
            button-cancel="border"
            :cancel-text="$t('action.cancel')"
            :accept-text="$t('action.change_password')"
            @accept="changePassword"
            @cancel="activePromptChangePassword = false"
            @close="close"
            :active.sync="activePromptChangePassword"
            :is-valid="validateChangePasswordForm">

            <vs-input
                id="user_password"
                :label="$t('nova-senha')"
                v-model="model.password"
                v-validate="'required'"
                class="w-full" />
              <span class="text-danger text-sm" v-show="errors.has('user_password')">{{errors.first('user_password')}}</span>
        </vs-prompt>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'


import UserService from '@/services/api/UserService'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    userService: null,
    loginService: null,
    model: {
      id: null,
      name: null,
      email: null,
      password: null
    },
    user: {},
    activePromptChangePassword: false
  }),
  watch: {

  },
  computed: {

    validateForm() {
      return (this.model && (this.model.email !== null))
    },
    validateChangePasswordForm(){
      return (this.model && this.model.password !== null && this.model.password.length > 2)
    },
    enableTabUser(){
      const acl = this.$acl
      return acl.hasPermission('backoffice.user')
    },
  },
  beforeMount() {
    this.userService = UserService.build(this.$vs)
  },
  mounted() {

  },

  methods: {
    loadDataGrid() {
      if(this.user){
        this.$refs['dataGrid'].fetchGridData(true)
      }else{
        this.clearData()
      }
    },
    loadData(){
      this.$vs.loading()

      const params = {
        email: this.model.email
      }

      this.userService.findUser(params).then(
        response => {
          this.user = response
          if((!this.user || !this.user.id)){
            this.notifyWarning(this.$vs, this.$t('nenhum-resultado-encontrado'),4000,'center-top')
          }
        },
        error => {

        }
      ).finally(
        () => {this.$vs.loading.close()}
      )
    },
    clearFilter(){
      this.model = {
        id: null,
        name: null,
        email: null,
        password: null,
      }
      this.clearData()
    },
    activateUser() {
      const userId = (this.user && this.user.id) ? this.user.id : null
      if(userId){
        this.userService.activateUser(userId).then(
          response => {
            this.user = response

            this.notifySuccess(this.$vs,this.$t('sucesso'),5000,'center-top')
          },
          error => {
            this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))
          }
        ).finally(
          () => {
            this.$vs.loading.close()
          }
        )
      }
    },
    resetPassword() {
      const userId = (this.user && this.user.id) ? this.user.id : null
      const data = {
        email: this.user.email
      }
      if(userId){
        this.userService.forgotPassword(userId, data).then(
          response => {
            this.notifySuccess(this.$vs,this.$t('sucesso'),5000,'center-top')
          },
          error => {
            this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))
          }
        ).finally(
          () => {
            this.$vs.loading.close()
          }
        )
      }
    },
    openChangePassword(){
      this.activePromptChangePassword = true
    },
    changePassword() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      const userId = (this.user && this.user.id) ? this.user.id : null
      const data = {
        email: this.user.email,
        password: this.model.password
      }
      if(userId){
        this.userService.changePassword(userId, data).then(
          response => {
            this.activePromptChangePassword = false
            this.model.password = ''

            this.notifySuccess(this.$vs,this.$t('sucesso'),5000,'center-top')
          },
          error => {
            //this.showErrors(this.$validator, error)
            this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
          }
        ).finally(
          () => {
            this.$vs.loading.close()
          }
        )
      }
    },
    clearData(){
      this.user = {}
      //this.$refs['dataGrid'].model.data= []
      //this.$refs['dataGrid'].resetFilters()
    },
    isAdmin() {
      return isAdmin()
    }
  }
}
</script>

<style>
.vs-list--header{
  display: inline !important;
}

</style>
