var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "flex flex-col gap-4 mt-4" }, [
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { id: "user_name", label: _vm.$t("name") },
                model: {
                  value: _vm.model.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "name", $$v)
                  },
                  expression: "model.name",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("user_name"),
                      expression: "errors.has('user_name')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("user_name")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { id: "user_email", label: _vm.$t("email") },
                model: {
                  value: _vm.model.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "email", $$v)
                  },
                  expression: "model.email",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("user_email"),
                      expression: "errors.has('user_email')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("user_email")))]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "w-full flex gap-2 pt-2 justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "mr-2",
                attrs: { disabled: !_vm.validateForm },
                on: { click: _vm.loadData },
              },
              [_vm._v(_vm._s(_vm.$t("action.consult")))]
            ),
            _c(
              "vs-button",
              {
                staticClass: "mr-2",
                attrs: { type: "border" },
                on: { click: _vm.clearFilter },
              },
              [_vm._v(_vm._s(_vm.$t("action.clear")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-tabs",
        [
          _vm.enableTabUser
            ? _c(
                "vs-tab",
                { attrs: { label: _vm.$t("backoffice.user_title") } },
                [
                  _c("vx-card", { ref: "results", staticClass: "mb-4" }, [
                    _vm.user.id
                      ? _c("div", [
                          _c("div", { staticClass: "vx-row w-full" }, [
                            _c("div", { staticClass: "vx-col" }, [
                              _c("b", [_vm._v(_vm._s(_vm.$t("name")) + ":")]),
                            ]),
                            _c("div", { staticClass: "vx-col" }, [
                              _vm._v(" " + _vm._s(_vm.user.name) + " "),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-row w-full pt-2" }, [
                            _c("div", { staticClass: "vx-col" }, [
                              _c("b", [_vm._v(_vm._s(_vm.$t("email")) + ":")]),
                            ]),
                            _c("div", { staticClass: "vx-col" }, [
                              _vm._v(" " + _vm._s(_vm.user.email) + " "),
                            ]),
                          ]),
                          _vm.user.id
                            ? _c("div", { staticClass: "vx-row w-full pt-2" }, [
                                _c("div", { staticClass: "vx-col" }, [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.$t("cadastrado-desde")) + ":"
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "vx-col" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$utils.format.longDateWithTime(
                                          _vm.user.created_at
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "vx-row w-full pt-2" }, [
                            _c("div", { staticClass: "vx-col" }, [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("ativado-em")) + ":"),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$utils.format.longDateWithTime(
                                      _vm.user.email_verified_at,
                                      "nao-ativada"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            !_vm.user.email_verified_at ||
                            !_vm.user.password_set_at
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: "backoffice.user.activate",
                                        expression:
                                          "'backoffice.user.activate'",
                                      },
                                    ],
                                    staticClass: "vx-col",
                                  },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        staticClass: "float-left",
                                        attrs: { type: "flat", icon: "check" },
                                        on: { click: _vm.activateUser },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("action.activate"))
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "vx-row w-full pt-2" }, [
                            _c("div", { staticClass: "vx-col" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("senha-preenchida-em")) + ":"
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$utils.format.longDateWithTime(
                                      _vm.user.password_set_at,
                                      "nao-preenchida"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-row w-full pt-2" }, [
                            _c("div", { staticClass: "vx-col" }, [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("ultimo-acesso")) + ":"),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$utils.format.longDateWithTime(
                                      _vm.user.last_access
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-row w-full pt-2" }, [
                            _c("div", { staticClass: "vx-col" }, [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("atualizado-em")) + ":"),
                              ]),
                            ]),
                            _c("div", { staticClass: "vx-col" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$utils.format.longDateWithTime(
                                      _vm.user.updated_at
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "vx-row mt-4" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col w-full flex gap-2 justify-end",
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "float-right mr-2",
                                    attrs: { disabled: !_vm.validateForm },
                                    on: { click: _vm.resetPassword },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("action.reset_password"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "float-right mr-2",
                                    attrs: { disabled: !_vm.validateForm },
                                    on: { click: _vm.openChangePassword },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("action.change_password"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !_vm.user.id
                      ? _c("div", { staticClass: "vx-row w-full pt-2" }, [
                          _c("div", { staticClass: "vx-col" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("nenhum-registro-encontrado")) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "vs-prompt",
                    {
                      attrs: {
                        color: "warning",
                        title: _vm.$t("digite-sua-nova-senha"),
                        type: "confirm",
                        "button-cancel": "border",
                        "cancel-text": _vm.$t("action.cancel"),
                        "accept-text": _vm.$t("action.change_password"),
                        active: _vm.activePromptChangePassword,
                        "is-valid": _vm.validateChangePasswordForm,
                      },
                      on: {
                        accept: _vm.changePassword,
                        cancel: function ($event) {
                          _vm.activePromptChangePassword = false
                        },
                        close: _vm.close,
                        "update:active": function ($event) {
                          _vm.activePromptChangePassword = $event
                        },
                      },
                    },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: {
                          id: "user_password",
                          label: _vm.$t("nova-senha"),
                        },
                        model: {
                          value: _vm.model.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "password", $$v)
                          },
                          expression: "model.password",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("user_password"),
                              expression: "errors.has('user_password')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("user_password")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }